@import "variables";

.sidebar {
  position: fixed;
  top: 0px;
  width: 250px;
  height: 100%;
  background: $sidebar-bg-color;
  .menu-group {
    margin: 25% 10%;
    .menu-item {
      font-weight: bold;
      padding: 10px 20px;
      border-radius: 7px;
      border: 2px solid #1fbee7;
      margin-top: 25px;
      text-decoration: none;
      color: white !important;
      font-size: 14px;
    }
    .active {
      font-weight: bold;
      background-color: #1fbee7;
    }
  }
  .base-card {
    background-color: $yellow-color;
    border-radius: 20px !important;
    width: 150px;
    min-height: 180px;
    padding: 2px;
    position: relative;
    .g-dot {
      position: absolute;
      right: -2px;
      top: -1px;
    }
    .first-txt {
      font-size: smaller;
    }
    .second-txt {
      font-size: xx-small;
      margin-top: 5px;
    }
    button {
      position: absolute;
      top: 75px;
      background-color: $primary-color;
      left: 18px;
      width: 50px;
      padding: 2px !important;
      font-size: small;
      color: white;
      z-index: 5;
    }
    img {
      position: absolute;
      bottom: -2px;
      right: -2px;
    }
  }
  .sd-circle-big {
    position: absolute;
    top: 70px;
    right: 0;
    z-index: -100;
  }
  .sd-circle-small {
    position: absolute;
    top: 80px;
    right: 20px;
    z-index: -100;
  }
}

@media only screen and (max-width: 768px) {
  .sidebar {
    .menu-group {
      margin-right: 7px;
      .menu-item {
        border-top-left-radius: 0px !important;
        border-bottom-left-radius: 0px !important;
        border-top-right-radius: 20px !important;
        border-bottom-right-radius: 20px !important;
      }
    }
    right: -300px;
    transition: right 700ms;
    z-index: 1000;
    .close-icon {
      margin-right: 90%;
    }

    .base-card {
      margin: auto;
    }
  }

  .expanded {
    right: 0px;
    z-index: 1000;
    transition: right 700ms;
  }
}

.business-btn {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  padding: 7px 15px;
  border: none;
  margin: 15px 18px;
  box-shadow: 5px 5px 7px #888888;
  font-weight: bold;
  color: $primary-color;
}
