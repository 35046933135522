@import "variables";

html {
  height: 100%;
}

body {
  background: #f8f9fa !important;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  #root {
    height: 100%;
  }
}

.App {
  height: 100%;
}

form {
  max-width: 600px;
  .wrapper {
    border-radius: 26px;
    border: 1px solid rgb(182, 179, 179);
    background: $sidebar-bg-color !important;
    padding: 1em;
    min-height: 60px;
    // input,
    // textarea,
    // select {
    //   border: 0;
    //   width: 100%;
    //   // outline: none;
    //   &:focus {
    //     outline: none !important;
    //     border: none !important;
    //   }
    // }
    // select {
    //   background: url("data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0Ljk1IDEwIj48ZGVmcz48c3R5bGU+LmNscy0xe2ZpbGw6I2ZmZjt9LmNscy0ye2ZpbGw6IzQ0NDt9PC9zdHlsZT48L2RlZnM+PHRpdGxlPmFycm93czwvdGl0bGU+PHJlY3QgY2xhc3M9ImNscy0xIiB3aWR0aD0iNC45NSIgaGVpZ2h0PSIxMCIvPjxwb2x5Z29uIGNsYXNzPSJjbHMtMiIgcG9pbnRzPSIxLjQxIDQuNjcgMi40OCAzLjE4IDMuNTQgNC42NyAxLjQxIDQuNjciLz48cG9seWdvbiBjbGFzcz0iY2xzLTIiIHBvaW50cz0iMy41NCA1LjMzIDIuNDggNi44MiAxLjQxIDUuMzMgMy41NCA1LjMzIi8+PC9zdmc+")
    //     no-repeat 100% 50%;
    //   -moz-appearance: none;
    //   -webkit-appearance: none;
    //   appearance: none;
    // }
  }
  button {
    padding: 0.5em 1.5em;
    border-radius: 20px;
    color: white;
    outline: none;
    border: none;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.center-children-vertical-and-horizontal {
  display: flex;
  align-items: center;
  justify-content: center;
}

.input-feedback {
  color: red !important;
}

.text-input.error {
  border-color: red !important;
  .form-icon {
    color: red !important;
  }
  ::placeholder {
    /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: red;
    opacity: 1; /* Firefox */
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: red;
  }

  ::-ms-input-placeholder {
    /* Microsoft Edge */
    color: red;
  }
}

.modal {
  button.close {
    color: white;
    outline: none !important;
    border: none;
    visibility: hidden;
    // background-color: grey;
  }
  .modal-footer,
  .modal-header {
    border: 0;
  }
  .modal-title {
    width: 100%;
    font-weight: normal;
    margin: 0;
  }
  .modal-body {
    padding-top: 0 !important;
  }
  .modal-footer {
    justify-content: center !important;
  }
}

.btn-modal-accept {
  background-color: #24c790;
  padding: 0.2rem 2rem;
  color: white;
  outline: 0;
  &:hover {
    cursor: pointer;
    background-color: #32b88b;
  }
  &:focus {
    background-color: #32b88b !important;
  }
}
.btn-modal-cancel {
  background-color: white;
  color: red;
  outline: 1px solid red;
  padding: 0.2rem 2rem;

  &:hover {
    cursor: pointer;
    background-color: whitesmoke;
    color: red;
  }
  &:focus {
    background-color: whitesmoke;
    color: red;
  }
}
.swal-button,
.swal-button:hover {
  &.swal-button--confirm {
    background-color: "#1FBEE7";
  }
}
.nav-tabs {
  .nav-link {
    border: 0 !important;
    border-bottom: inherit !important;
  }
  .nav-link.active {
    color: #006ab5 !important;
    border: 0 !important;
    border-bottom: 2px solid #006ab5 !important;
    &:hover {
      border: 0 !important;
      border-bottom: 2px solid #006ab5 !important;
    }
  }
}

.notifications-card {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.notifications-card::-webkit-scrollbar {
  display: none;
}

.dropdown-item:hover,
.dropdown-item:active {
  background-color: white;
}

textarea {
  resize: none;
}
.text-teal {
  color: #32b88b;
}

.cursor-pointer {
  cursor: pointer;
}

.pull-left {
  float: left;
  padding-right: 20px;
}

.capitlize {
  text-transform: capitalize;
}

//making all columns in a row have the same height
.row {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-wrap: wrap;
}
.row > [class*="col-"] {
  display: flex;
  flex-direction: column;
}

.modals-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

.grid-md {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 20px;
}

@media screen and (max-width: 768px) {
  .grid-md {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
  }
}

a {
  color: inherit;
  &:hover {
    color: inherit;
  }
}


.blink {
  animation: blink 1s steps(1, end) infinite;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
