@import "variables";


.auth-card {
  border-radius: 16px !important;
  padding: 15px;
  // width: 300px;
  height: 450px;
  .card-title {
    color: $auth-button-bg-color;
    font-size: xx-large;
    font-weight: lighter !important;
  }
  .card-sub-title {
    font-weight: lighter !important;
  }
  form {
    button {
      margin-top: 25px !important;
      width: 100%;
      padding: 10px;
      border-radius: 12px;
      background-color: $auth-button-bg-color;
      color: $auth-button-text-color;
      font-size: xx-large;
      font-weight: bold;
    }
  }
  input {
    // padding: 15px !important;
  }
}
