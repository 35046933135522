$primary-color: #511e2e;
$secondary-color: #61ce70;
$orange-color: #fb6d3a;
$yellow-color: #ffc247;
$grey-color: #e5e5e5;
$text-muted-color: #6c757d;
$white-color: #ffffff;
$light-color: #f6f6f5;
$auth-button-bg-color: #24c790;
$auth-button-text-color: white;
$sidebar-bg-color: #454d68;
