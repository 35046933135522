@import "variables";

.page-body::-webkit-scrollbar {
  display: none;
}

.s-layout {
  display: flex;
  .page-body {
    margin-left: 250px;
    height: 100%;
    min-height: 100vh;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none;
    padding: 20px;
    .search {
      display: flex;
      align-items: center;
      background-color: $light-color;
      border-radius: 15px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .s-layout {
    .page-body {
      height: 100%;
      min-height: 100vh;
      margin-left: 0 !important;
    }
  }
}
